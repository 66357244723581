import { menu } from "./modules/menu";
import { sendEmail } from "./modules/sendEmail";

function ready() {
  const form = document.querySelectorAll('form');
  menu()
  if (form.length > 0) {
    sendEmail(form)
  }

}

document.addEventListener("DOMContentLoaded", ready);
