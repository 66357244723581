export function sendEmail(form) {
    const inputs = document.querySelectorAll('input');
    const message = {
        loading: 'sending...',
        success: 'Thanks for request!',
        description: 'Stay in touch and don\'t forget to check your email.',
        failure: 'Error'
    }

    const postData = async (requestOptions, index) => {
        const status = document.querySelectorAll('.status');
        status[index].classList.add('absolute');
        status[index].textContent = message.loading;
        const response = await fetch(`${process.env.API || ''}/api/v1/beta/invites`, requestOptions)
        const res = await response.json();
        localStorage.setItem('emailSend', 'true');
        return res;
    }

    const clear = () => {
        for (let i = 0; i < inputs.length; i += 1) {
            inputs[i].value = ''
        }
    }
    form.forEach((item, index) => {
        const statusMessages = [];
        if (localStorage.getItem('emailSend')) {
            item.email.parentNode.remove()
            item.button.remove()
            const title = item.querySelector('h3')
            const img = item.querySelector('img')
            const description = item.querySelector('p')
            img.style.display = 'block'
            title.textContent = message.success
            title.style.marginBottom = '12px'
            description.textContent = message.description
            item.classList.add('success')
        }
        item.addEventListener('submit', (e) => {
            e.preventDefault();
            statusMessages[0] = document.createElement('div');
            statusMessages[0].classList.add('status');
            form[0].appendChild(statusMessages[0]);
            statusMessages[1] = document.createElement('div');
            statusMessages[1].classList.add('status');
            form[1].appendChild(statusMessages[1]);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const email = e.target.email.value
            const raw = JSON.stringify({email});

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
                body: raw
            };
            postData(requestOptions, index).then(()=>{
                statusMessages[index].classList.remove('absolute');
                form.forEach((itemReq, indexReq) => {
                    itemReq.email.parentNode.remove()
                    itemReq.button.remove()
                    statusMessages[indexReq].remove()
                    const title = itemReq.querySelector('h3')
                    const img = itemReq.querySelector('img')
                    const description = itemReq.querySelector('p')
                    img.style.display = 'block'
                    title.textContent = message.success
                    title.style.marginBottom = '12px'
                    description.textContent = message.description
                    itemReq.classList.add('success')
                })
            })
                .catch(() => {
                    statusMessages[index].classList.remove('absolute');
                })
                .finally(()=>{
                    clear()
                    setTimeout(() => {
                        statusMessages[0].remove()
                        statusMessages[1].remove()
                    }, 5000);
                })
        })
    })
}
