export function menu() {
  const hamburgers = document.querySelectorAll('.hamburger');
  if (hamburgers.length > 0) {
    const menuEl = document.querySelector('.menu');
    const closeEl = document.querySelector('.menu__close');
    const closeButton = document.querySelectorAll('.button__close');
    const overlay = document.querySelector('.menu__overlay');

    for (let i = 0; i < hamburgers.length; i += 1) {
      hamburgers[i].addEventListener('click', () => {
        menuEl.classList.add('active')
        overlay.style.display = 'block'
      })
    }
    const buttonClosed = Array.from(closeButton).concat([closeEl, overlay])
    for (let i = 0; i < buttonClosed.length; i += 1) {
      buttonClosed[i].addEventListener('click', () => {
        menuEl.classList.remove('active')
        overlay.style.display = 'none'
      })
    }
  }
}
